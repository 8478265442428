import { ref } from 'vue';
import { Toast } from 'vant';
import { VIP_PACKAGES, VIP_OPEN, WX_JSSDK } from '../../http/api';

const wx = require('weixin-js-sdk');

import { computed } from 'vue';
export default {
  setup() {
    const chosenAddressId = ref(0);
    const list = ref(null);
    const defaultBgColor = ref("#fff"); //const route = useRoute()
    //const path = computed(() => window.location.protocol + "//" + window.location.host+ "/#" +route.path)

    const path = computed(() => window.location.protocol + "//" + window.location.host + "/"); // 获取权限

    WX_JSSDK({
      url: path.value
    }).then(sdkConf => {
      wx.config({
        debug: false,
        appId: sdkConf.data.appId,
        timestamp: sdkConf.data.timestamp,
        nonceStr: sdkConf.data.nonceStr,
        signature: sdkConf.data.signature,
        jsApiList: ["chooseWXPay"]
      });
      Toast.clear();
    }); // 获取充值套餐列表

    VIP_PACKAGES().then(res => {
      Toast.clear();
      let rows = new Array();

      if (res.data.data) {
        for (let i in res.data.data) {
          let tag = "";

          if (res.data.data[i].is_default) {
            //chosenAddressId.value = res.data.data[i].i
            tag = "最优推荐";
          }

          rows.push({
            id: res.data.data[i].id,
            name: res.data.data[i].name,
            oldPrice: res.data.data[i].cost_price / 100,
            newPrice: res.data.data[i].new_price / 100,
            tag: tag,
            duration: res.data.data[i].duration,
            desc: res.data.data[i].desc
          });
        }

        list.value = rows;
      }
    });

    const onAdd = id => {
      VIP_OPEN({
        package_id: id
      }).then(res => {
        Toast.clear();

        if (res.data.code !== 0) {
          Toast(res.data.msg);
        } else {
          wx.chooseWXPay({
            timestamp: res.data.data.timestamp,
            nonceStr: res.data.data.nonceStr,
            package: "prepay_id=" + res.data.data.package,
            signType: res.data.data.signType,
            paySign: res.data.data.paySign,
            success: function (res) {
              if (res.errMsg === 'chooseWXPay:ok') {
                Toast.success({
                  type: "success",
                  duration: 1000,
                  message: "会员开通成功",

                  onClose() {
                    window.location.href = "/#subscribe_setting";
                  }

                });
              }
            },
            fail: function () {
              Toast.fail("支付已取消");
            }
          });
        }
      });
    };

    return {
      list,
      chosenAddressId,
      onAdd
    };
  }

};